
<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
defineProps({
    open: {
        type: Boolean,
        required: true,
    },
});

const emits = defineEmits(['update:open', 'closing']);

function setClosed() {
    emits('closing');
    emits('update:open', false);
}
</script>

<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="setClosed">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 transition-opacity bg-white/30" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative w-full px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white shadow-lg  rounded-2xl sm:my-8 sm:max-w-lg md:max-w-screen-lg sm:p-6"
                        >
                            <div class="absolute top-0 right-0 hidden pt-2 pr-2 sm:block">
                                <button
                                    type="button"
                                    class="text-black bg-white rounded-md hover:text-black"
                                    @click="setClosed"
                                >
                                    <span class="sr-only">Close</span>
                                    <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div>
                                <div class="text-center sm:mt-0 sm:mr-2 sm:text-left">
                                    <slot></slot>
                                </div>
                            </div>
                            <div class="justify-end flex-col md:flex-row gap-3 mt-5 sm:mt-4 flex">
                                <slot name="button-bar"></slot>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
